import { GET_PAGE } from '../action-types'
import { SET_PAGE } from '../mutation-types'
import pageModel from '../../models/page'

export default {
  namespaced: true,
  actions: {
    async [GET_PAGE] ({ commit }, id) {
      commit(SET_PAGE, await pageModel.get(id))
    },
  },
  mutations: {
    [SET_PAGE] (state, page) {
      state.translations = { ...state.translations, ...page }
      // Object.keys(page).forEach(
      //   key => {
      //     state[key] = page[key]
      //   },
      // )
    },
  },
  // We initialize the state with
  // an empty LandingPage object.
  state: {
    translations: {},
  },
}
