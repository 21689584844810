<template>
  <v-theme-provider :dark="dark">
    <v-container class="pa-8">
      <v-row
        :justify="justify"
        no-gutters
      >
        <v-col
          v-if="icon"
          :class="`text-${align}`"
          cols="12"
          class="mb-4"
          style="cursor: pointer"
          @click="scrollDown(scrollTo)"
        >
          <div v-animate-onscroll="'animated swing'">
            <base-avatar
              v-if="icon"
              :icon="icon"
              :outlined="false"
              color="primary"
              dark
            />
          </div>
        </v-col>

        <v-col
          :cols="callout ? 9 : 12"
        >
          <base-subtitle
            v-if="subtitle"
            :title="subtitle"
            space="1"
          />

          <base-title
            :title="title"
            class="text-uppercase"
            space="1"
          />
        </v-col>

        <v-col
          v-if="callout"
          cols="2"
        >
          <div
            class="display-3 grey--text text--lighten-4 font-weight-bold pr-8"
            v-text="callout"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-theme-provider>
</template>

<script>
  // Mixins
  import Heading from '@/mixins/heading'

  export default {
    name: 'BaseDepartmentCard',

    mixins: [Heading],

    props: {
      dark: Boolean,
      callout: String,
      color: {
        type: String,
        default: 'primary',
      },
      icon: String,
      scrollTo: String,
      subtitle: String,
      text: String,
      title: String,
    },
    methods: {
      scrollDown (selector) {
        const target = document.querySelector(`#${selector}`)
        if (target) {
          target.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          })
        }
      },
    },
  }
</script>
