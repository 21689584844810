<template>
  <v-fade-transition mode="out-in">
    <router-view />
  </v-fade-transition>
</template>

<script>
  import FetchPage from '@/mixins/fetchPage'
  export const ALL = '56eRWH4oIMUDXfeny0m85'
  export const STORE_NAMESPACE_ALL = 'page/all'

  export default {
    name: 'App',
    mixins: [
      FetchPage(ALL, STORE_NAMESPACE_ALL),
    ],
    metaInfo: {
      title: '',
      titleTemplate: '%s | Chemorozruch',
      htmlAttrs: { lang: 'en' },
      meta: [
        { charset: 'utf-8' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      ],
    },
  }
</script>

<style lang="sass">
  .extra-padding
    padding-bottom: 96px !important
    padding-top: 96px !important

    @media screen and (max-width: 959px)
      padding-top: 48px !important
      padding-bottom: 48px !important
</style>
