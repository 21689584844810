import page from '../store/modules/page'
import { GET_PAGE } from '../store/action-types'
import store from '../store/index'

export default function (pageId, storeNamespace) {
  return {
    name: 'FetchPage',
    mounted () {
      if (!store.state[storeNamespace]) {
        store.registerModule(storeNamespace, page)
      }
      if (store.state[storeNamespace].locale === store.state.language) {
        return
      }
      store.dispatch(`${storeNamespace}/${GET_PAGE}`, pageId)
    },
  }
}
