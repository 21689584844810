import Vue from 'vue'
import Vuex from 'vuex'
import { SET_LANG } from './mutation-types'
import { PL, EN } from '@/constants/lang.js'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
      language: 'pl-PL',
    },
    mutations: {
      [SET_LANG] (state, language) {
        state.language = language
      },
    },
  })
