<template>
  <v-theme-provider>
    <div>
      <base-info-card
        :title="title"
        pace="6"
      />

      <base-business-contact
        :business="business"
        dense
      />
    </div>
  </v-theme-provider>
</template>

<script>
  export default {
    name: 'BaseBusinessInfo',

    props: {
      title: {
        type: String,
      },
      business: {
        type: Array,
      },
    },
  }
</script>
