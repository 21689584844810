<template>
  <v-container
    class="pa-2 home-card"
  >
    <v-row
      :justify="justify"
      no-gutters
    >
      <slot />
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'HomeCard',
    props: {
      justify: {
        type: String,
      },
    },
  }
</script>

<style lang="scss">

  .home-card{
    background-color: rgba(0, 0, 0, 0.4); border-color: rgba(0, 0, 0, 0.3);
    border-radius: 4px;
  }
</style>
