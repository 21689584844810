<template>
  <v-theme-provider :dark="dark">
    <v-container
      class="pa-0"
      :class="{pointer: scrollId}"
      @click="scrollDown(scrollId)"
    >
      <v-row
        :justify="justify"
        no-gutters
      >
        <v-col
          v-if="icon"
          :class="`text-${align}`"
          cols="12"
          class="mb-4"
        >
          <div v-animate-onscroll="scrollId ? 'animated swing' : null">
            <base-icon :color="color">
              {{ icon }}
            </base-icon>
          </div>
        </v-col>

        <v-col
          :cols="callout ? 9 : 12"
        >
          <base-subtitle
            v-if="subtitle"
            :title="subtitle"
            space="1"
          />

          <base-title
            :title="title"
            class="text-uppercase"
            :class="{'text-right' : right}"
            space="1"
            :size="titleSize"
          />

          <base-divider
            v-if="title || subtitle"
            :color="color"
            :align="align"
          />

          <base-body
            v-if="text || $slots.default"
            :text="text"
            space="6"
            :class="{'text-right' : right}"
          >
            <slot />
          </base-body>
        </v-col>

        <v-col
          v-if="callout"
          cols="2"
        >
          <div
            class="display-3 grey--text text--lighten-4 font-weight-bold pr-8"
            v-text="callout"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-theme-provider>
</template>

<script>
  // Mixins
  import Heading from '@/mixins/heading'

  export default {
    name: 'BaseInfoCard',

    mixins: [Heading],

    props: {
      dark: Boolean,
      callout: String,
      color: {
        type: String,
        default: 'primary',
      },
      icon: String,
      subtitle: String,
      text: String,
      title: String,
      scrollId: String,
      right: Boolean,
      titleSize: String,
    },
    methods: {
      scrollDown (selector) {
        const target = document.querySelector(`#${selector}`)
        if (target) {
          target.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          })
        }
      },
    },
  }
</script>

<style scoped lang="scss">
  .display-2 {
   font-weight: 400!important;
  }
</style>
