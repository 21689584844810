<template>
  <v-img
    v-bind="$attrs"
    :max-height="maxHeight"
    :contain="contain"
    v-on="$listeners"
  >
    <slot />
  </v-img>
</template>

<script>
  export default {
    name: 'BaseImage',
    props: {
      contain: {
        type: Boolean,
        default: true,
      },
      maxHeight: {
        type: String,
        default: '500px',
      },
    },
  }
</script>
