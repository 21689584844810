import api from '../utils/api'
import store from '../store/index'
import { isArray } from 'leaflet/src/core/Util'

export function responseAdapter (response) {
  const { fields, sys } = response.sys.type === 'Array'
    ? response.items[0]
    : response

  Object.keys(fields).forEach(
    key => {
      if (isArray(fields[key])) {
        fields[key] = fields[key].map(item => item.fields)
      }
    },
  )

  return ({
    ...fields,
    ...sys,
  })
}

export default {
  async get (id) {
    return responseAdapter(await api.getEntries({ 'sys.id': id, locale: store.state.language }))
  },
}
