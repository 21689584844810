<template>
  <v-theme-provider :dark="dark">
    <div>
      <base-img
        :src="require('@/assets/logo.svg')"
        contain
        max-width="128"
        min-height="78"
        width="100%"
      />

      <base-title
        size="body-1"
        space="4"
        :title="title"
        weight="regular"
      />

      <base-body>
        {{ description }}
      </base-body>

      <base-btn
        class="mb-12"
        color="white"
        outlined
        @click="scrollDown('app')"
      >
        {{ translations.scrollUp }}
      </base-btn>
    </div>
  </v-theme-provider>
</template>

<script>
  import { STORE_NAMESPACE_ALL } from '@/App.vue'
  import { mapState } from 'vuex'
  import { documentToHtmlString } from '@contentful/rich-text-html-renderer'

  export default {
    name: 'BaseInfo',

    props: {
      title: String,
      dark: Boolean,
      description: String,
    },
    computed: {
      ...mapState(STORE_NAMESPACE_ALL, [
        'translations',
      ]),
    },
    data: () => ({
      business: [
        {
          icon: 'mdi-map-marker-outline',
          title: 'Address',
          text: '8553 N. Beach St. Ste. 227<br>Fort Worth, Texas 76137',
        },
        {
          icon: 'mdi-cellphone',
          title: 'Phone',
          text: '01 (800) 433 744<br>01 (800) 433 633',
        },
        {
          icon: 'mdi-email',
          title: 'Email',
          text: 'john@vuetifyjs.com<br>heather@vuetifyjs.com',
        },
      ],
    }),
    methods: {
      scrollDown (selector) {
        const target = document.querySelector(`#${selector}`)
        if (target) {
          target.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          })
        }
      },
    },
  }
</script>
